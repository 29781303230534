"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getActiveStudentAccountAmount = exports.getParentWechatActiveAccountAmount = exports.getActivatedAccountHistoricalStats = exports.listBureauTags = void 0;
var base_1 = require("./base");
function listBureauTags() {
    return __awaiter(this, void 0, void 0, function () {
        var r;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, base_1.service.get("/bureau/v1.2/listTags", { params: { page: 1, limit: 999999, category: 'bureau' } })];
                case 1:
                    r = _a.sent();
                    return [2 /*return*/, r.data.list];
            }
        });
    });
}
exports.listBureauTags = listBureauTags;
/**
 * https://guobao.w.eolink.com/home/api-studio/inside/BV78zrI2bc5d632a072cda493517349b4001dd2ccc78a21/api/2905623/detail/53814294?spaceKey=guobao&eo_login=1
 * @param start
 * @param end
 * @param tags
 * @param bureau_ids
 * @param require_bureau_valid
 * @returns
 */
function getActivatedAccountHistoricalStats(start, end, tags, bureau_ids, require_bureau_valid) {
    if (tags === void 0) { tags = []; }
    if (bureau_ids === void 0) { bureau_ids = []; }
    if (require_bureau_valid === void 0) { require_bureau_valid = 1; }
    return __awaiter(this, void 0, void 0, function () {
        var params, r;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = {
                        start: start.toISOString(),
                        end: end.toISOString(),
                        tags: tags,
                        bureau_ids: bureau_ids,
                        require_bureau_valid: require_bureau_valid
                    };
                    return [4 /*yield*/, base_1.service.get("/admin/v1/getActivatedAccountHistoricalStats", { params: params })];
                case 1:
                    r = _a.sent();
                    return [2 /*return*/, r.data];
            }
        });
    });
}
exports.getActivatedAccountHistoricalStats = getActivatedAccountHistoricalStats;
/**
 * https://guobao.w.eolink.com/home/api-studio/inside/BV78zrI2bc5d632a072cda493517349b4001dd2ccc78a21/api/2905623/detail/53816312?spaceKey=guobao&eo_login=1
 * @param start
 * @param end
 * @returns
 */
function getParentWechatActiveAccountAmount(start, end) {
    return __awaiter(this, void 0, void 0, function () {
        var params, r;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = { start: start.toISOString(), end: end.toISOString() };
                    return [4 /*yield*/, base_1.service.get("/admin/v1/getParentWechatActiveAccountAmount", { params: params })];
                case 1:
                    r = _a.sent();
                    return [2 /*return*/, r.data];
            }
        });
    });
}
exports.getParentWechatActiveAccountAmount = getParentWechatActiveAccountAmount;
/**
 * https://guobao.w.eolink.com/home/api-studio/inside/BV78zrI2bc5d632a072cda493517349b4001dd2ccc78a21/api/2905623/detail/53814300?spaceKey=guobao&eo_login=1
 * @param start
 * @param end
 * @param tags
 * @param bureau_ids
 * @returns
 */
function getActiveStudentAccountAmount(start, end, tags, bureau_ids) {
    if (tags === void 0) { tags = []; }
    if (bureau_ids === void 0) { bureau_ids = []; }
    return __awaiter(this, void 0, void 0, function () {
        var params, r;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = {
                        start: start.toISOString(),
                        end: end.toISOString(),
                        tags: tags,
                        bureau_ids: bureau_ids
                    };
                    return [4 /*yield*/, base_1.service.get("/admin/v1/getActiveStudentAccountAmount", { params: params })];
                case 1:
                    r = _a.sent();
                    return [2 /*return*/, r.data];
            }
        });
    });
}
exports.getActiveStudentAccountAmount = getActiveStudentAccountAmount;
