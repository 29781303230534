"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertConventionalToNormalMonth = exports.convertConventionalToNormal = exports.exactNextMonth = exports.exactMonth = exports.getWeekRange = exports.exactNextWeek = exports.exactWeek = exports.exactNextDate = exports.exactDate = void 0;
var moment = require("moment");
/**
 * This date with 00:00:00
 * @param origin source date
 * @returns
 */
function exactDate(origin) {
    return moment(origin).startOf("day").toDate();
}
exports.exactDate = exactDate;
/**
 * The next date with 00:00:00
 * @param origin source date
 * @returns
 */
function exactNextDate(origin) {
    return moment(origin).add(1, "d").startOf("day").toDate();
}
exports.exactNextDate = exactNextDate;
function exactWeek(origin) {
    return moment(origin).startOf("week").toDate();
}
exports.exactWeek = exactWeek;
function exactNextWeek(origin) {
    return moment(origin).add(1, "week").startOf("week").toDate();
}
exports.exactNextWeek = exactNextWeek;
function getWeekRange(date) {
    var s = exactWeek(date);
    var e = exactNextWeek(date);
    return [s, e];
}
exports.getWeekRange = getWeekRange;
function exactMonth(origin) {
    return moment(origin).startOf("month").toDate();
}
exports.exactMonth = exactMonth;
function exactNextMonth(origin) {
    return moment(origin).add(1, "month").startOf("month").toDate();
}
exports.exactNextMonth = exactNextMonth;
function convertConventionalToNormal(start, end) {
    var s = exactDate(start);
    var e = exactNextDate(end);
    return [s, e];
}
exports.convertConventionalToNormal = convertConventionalToNormal;
function convertConventionalToNormalMonth(start, end) {
    var s = exactMonth(start);
    var e = exactNextMonth(end);
    return [s, e];
}
exports.convertConventionalToNormalMonth = convertConventionalToNormalMonth;
