"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var element_plus_1 = require("element-plus");
var vue_3 = require("vue");
var bureau_1 = require("../../napis/bureau");
exports.default = (0, vue_1.defineComponent)({
    expose: [],
    setup: function (__props) {
        var tags = (0, vue_3.ref)(undefined);
        (0, bureau_1.listBureauTags)().then(function (r) {
            tags.value = r;
        });
        return function (_ctx, _cache) {
            return (Boolean(tags.value))
                ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(element_plus_1.ElSelect), (0, vue_2.mergeProps)({ key: 0 }, _ctx.$attrs, { multiple: "" }), {
                    default: (0, vue_2.withCtx)(function () { return [
                        ((0, vue_2.openBlock)(true), (0, vue_2.createBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(tags.value, function (tag) {
                            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)((0, vue_2.unref)(element_plus_1.ElOption), {
                                key: tag.id,
                                label: tag.name,
                                value: tag.id
                            }, null, 8, ["label", "value"]));
                        }), 128))
                    ]; }),
                    _: 1
                }, 16))
                : (0, vue_2.createCommentVNode)("", true);
        };
    }
});
